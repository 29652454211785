















import Vue from "vue";
import TabList from "@/components/TabList.vue";
export default Vue.extend({
  components: { TabList },
  data: () => ({
    tab: {
      selectedTab: 0,
      tabTitle: "研究方向",
      tabs: [
        { text: "整体介绍", path: "/group" },
        { text: "语音前端", path: "/group/fe" },
        { text: "语音识别", path: "/group/asr" },
        { text: "语音合成", path: "/group/tts" },
        { text: "声纹识别", path: "/group/sv" },
      ],
    },
  }),
  created() {
    console.log("page", this.$route.name);
    if (this.$route.name == "ttsIndex") {
      this.tab.selectedTab = 3;
    } else if (this.$route.name == "asrIndex") {
      this.tab.selectedTab = 2;
    }else if (this.$route.name == "feIndex") {
      this.tab.selectedTab = 1;
    }else if (this.$route.name == "svIndex") {
      this.tab.selectedTab = 4;
    }
  },
});
